import React, { memo, useState } from 'react';
import { useStyles } from './MailItemStyles';
import { Box } from '@material-ui/core';
import { Mail } from '~/helpers/draggableHelpers';
import { useThemeContext } from '~/theme';
import moment from 'moment';
import classNames from 'classnames';

export type MailItemProps = {
  mail: Mail;
  onPress: () => void;
};

const MailItem: React.FC<MailItemProps> = ({
  mail,
  onPress
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();

  const [hovered, setHovered] = useState(false);

  return (
    <Box 
      className={classNames(s.container)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        backgroundColor: hovered? theme.colors.bgSeptenary: theme.colors.bgQuinary,
        transitionDuration: "0.4s"
      }}
      onClick={() => onPress()}
    >
      <Box className={classNames(s.row)}>
        <Box className={classNames(s.topLeft)}>
          <p className={classNames(s.textWhite)}>
            {mail.from.text}
          </p>
        </Box>
        <Box className={classNames(s.topRight)}>
          <p className={classNames(s.textWhiteBold)}>
            {moment(mail.date).format("ll")}
          </p>
        </Box>
      </Box>
      <Box className={classNames(s.bottom)}>
        <p className={classNames(s.grayText)}>
          {mail.subject}
        </p>
      </Box>
    </Box>
  );
};

MailItem.defaultProps={}

const MemorizedComponent = memo(MailItem);
export { MemorizedComponent as MailItem };