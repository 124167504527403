import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useStyles } from './RemoteSettingsTabStyles';
import { useRemoteSettingsTabAnalytics } from './useRemoteSettingsTabAnalytics';
import { Box, Typography, IconButton } from '@material-ui/core';
import { Virtuoso } from 'react-virtuoso';
import { Rnd as ResizeableView } from "react-rnd";
import { SwitchColored } from '~/components/SwitchColored';
import { ResizeHandle } from '~/components/ResizeHandle';
import { SlidingPickerV2 } from '~/components/SlidingPickerV2';
import { ObjectConstructor } from '~/components/ObjectConstructor';
import { RemoteSettings, RemoteSettingsActions, RemoteSettingsEnv } from '~/logic/remoteSettings/RemoteSettingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { remoteSettingsSelector } from '~/logic/remoteSettings/RemoteSettingsSelectors';
import { socketService } from '~/services/socketService';
import { ProjectItem } from "~/components/ProjectItem";
import { UserActions } from '~/logic/user/UserSlice';
import { ObjectT, Project } from '~/types/types';
import { useThemeContext } from '~/theme';
import { RefreshIcon } from '~/assets/icons';
import { NoData } from '~/components/NoData';
import { CustomButton } from '~/components/CustomButton';
import { resizeHandleRightStyle } from '~/constants/resizeHandleStyles';
import { CreateProjectModal } from "./CreateProjectModal";
import { MembersModal, MembersModalMode } from "./MembersModal";
import { TextInput } from '~/components/TextInput';
import { Note } from '~/components/Note';
import { useSearchValue } from '~/hooks';
import { userSelector } from '~/logic/user/UserSelectors';
import { useProjectIdInfo } from '~/hooks/useProjectIdInfo';
import { useWindowSize } from '~/hooks/useWindowSize';
import { useRndSizeState } from '~/hooks/useRndSizeState';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

const RND_LEFT_ID = "REMOTE_SETTINGS_TAB_LEFT_RND_2";
const RND_RIGHT_ID = "REMOTE_SETTINGS_RIGHT_RND_2";

const REMOTE_SETTINGS_ENV_ARRAY: RemoteSettingsEnv[] = ["dev", "stg", "prod"];
const EMPTY_REMOTE_SETTINGS: RemoteSettings = {dev: {}, stg: {}, prod: {}};

export type RemoteSettingsTabProps = {};

const RemoteSettingsTab: React.FC<RemoteSettingsTabProps> = ({}) => {
  const { ds, theme } = useThemeContext();
  const s = useStyles(theme)();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const {
    apiKey,
    connectedClientProjectId,
    profile: {
      data: profile,
    }
  } = useSelector(userSelector);

  const {
    selectedProjectId,
    remoteSettingsByProjectIdTable,
    getRemoteSettings: {
      fetching: fetchingRemoteSettings
    },
    setRemoteSettings: {
      fetching: fetchingSetRemoteSettings
    },
    projectList: {
      data: projectList,
      fetching: fetchingProjectList
    },
  } = useSelector(remoteSettingsSelector);

  const {
    trackRemoteSettingsUpdated
  } = useRemoteSettingsTabAnalytics();

  const { windowWidth } = useWindowSize();
  const [w, setW] = useState(windowWidth);
  const [lw, setLw] = useRndSizeState(RND_LEFT_ID, w, 0.45);
  const [rw, setRw] = useRndSizeState(RND_RIGHT_ID, w, 0.55);
  const containerRef = useRef<HTMLDivElement>(null);

  const [projectToEdit, setProjectToEdit] = useState<Project | null>(null);
  const [createProjectModalVisible, setCreateProjectModalVisible] = useState(false);
  const [membersModalMode, setMembersModalMode] = useState<MembersModalMode>("add");
  const [membersModalVisible, setMembersModalVisible] = useState(false);
  const [pickedEnv, setPickedEnv] = useState(0);

  const [searchKeysValue, searchKeysInnerValue, setSearchKeysInnerValue] = useSearchValue("");

  const { projectName } = useProjectIdInfo(selectedProjectId);

  const handleDeleteProject = (id: string, name: string) => {
    dispatch(UserActions.setActionModalState({
      visible: true,
      title: t("Toast.pleaseConfirmAction"),
      text: `${t("Toast.deletingProject")}: ${name}`,
      onPressYes: () => dispatch(RemoteSettingsActions.deleteProject.request({id})),
      onPressNo: () => {}
    }));
  }

  const handlePressEditProject = (project: Project) => {
    setProjectToEdit(project);
    setCreateProjectModalVisible(true);
  }

  const handleSaveNewRemoteSettings = (obj: ObjectT<string>) => {
    if (selectedProjectId) {
      const newRemoteSettings: RemoteSettings = {...EMPTY_REMOTE_SETTINGS};
      REMOTE_SETTINGS_ENV_ARRAY.forEach((env, index) => {
        newRemoteSettings[env] = JSON.parse(JSON.stringify(obj));

        if (index !== pickedEnv)
          for (const key of Object.keys(obj))
            newRemoteSettings[env][key] = remoteSettingsByProjectIdTable[selectedProjectId]?.[env]?.[key] ?? obj[key];
      });

      dispatch(RemoteSettingsActions.setRemoteSettings.request({
        remoteSettings: newRemoteSettings,
        projectId: selectedProjectId
      }));
      if (connectedClientProjectId && connectedClientProjectId === selectedProjectId) { // Если подключенный проект имеет projectId и он совпадает с selectedProjectId
        socketService.sendNewRemoteSettings(newRemoteSettings);
      }
      trackRemoteSettingsUpdated(Object.keys(obj).length);
    }
  }

  const handleToogleRemoteSettingsEnabled = (enabled: boolean) => {
    if (!selectedProjectId)
      return;

    dispatch(UserActions.editProfile.request({
      projectsSetting: {
        ...(profile?.projectsSetting ?? {}),
        [selectedProjectId]: {
          remoteSettingsEnabled: enabled
        }
      }
    }));
  }

  const onRefresh = () => {
    dispatch(RemoteSettingsActions.projectList.request({index: 0}));
    if (apiKey)
      dispatch(UserActions.personalSetting.request({apiKey}));
  };

  useLayoutEffect(() => {
    setW(containerRef?.current?.offsetWidth ?? windowWidth);
  }, [windowWidth]);

  // ComponentDidMount
  useEffect(() => {
    if (projectList === undefined)
      onRefresh();
  }, []);

  useEffect(() => {
    if (selectedProjectId) {
      dispatch(RemoteSettingsActions.getRemoteSettings.request({
        projectId: selectedProjectId
      }));
    }
  }, [selectedProjectId]);

  return (
    <div className={cn(ds.fullSpaceRelative)} ref={containerRef}>
      <ResizeableView
        disableDragging={true}
        enableResizing={{"right": true}}
        size={{ width: lw,  height: "100%" }}
        minWidth={windowWidth * 0.36}
        maxWidth={windowWidth * 0.55}
        onResize={(e, direction, ref) => {
          setLw(ref.offsetWidth);
          setRw(w - ref.offsetWidth);
        }}
        onResizeStop={(e, direction, ref) => dispatch(UserActions.setRndDefaultSizeSettings([{key: RND_LEFT_ID, value: ref.offsetWidth / w}, {key: RND_RIGHT_ID, value: (w - ref.offsetWidth) / w}]))}
        resizeHandleStyles={{"right": resizeHandleRightStyle}}
      >
        <Box className={cn(s.left)}>
          <Box className={cn(s.projectsCont)}>
            <Box className={cn(s.projectsControlBlock)}>
              <Box className={cn(s.projectsLeftButtonsCont)}>
                <Note 
                  alt={t("Common.betaFeature")}
                  fullText={t("MainPage.RemoteSettingsTab.remoteSettingsUsageDisclaimer")}
                />
              </Box>

              <Typography variant="h5" paragraph className={cn(s.projectsTitleTextBold)} style={{margin: 0}}>
                {`${t("MainPage.RemoteSettingsTab.availableProjects")}:`}
              </Typography>

              <Box className={cn(s.projectsRightButtonsCont)}>
                <IconButton 
                  onClick={onRefresh} 
                  size='small'
                  className={cn(s.refreshButton)}
                  title={t("MainPage.RemoteSettingsTab.refreshProjects")}
                >
                  <RefreshIcon color={theme.colors.iconsMain}/>
                </IconButton>
              </Box>
            </Box>

            <Box className={cn(s.projectListCont)}>
              <Box className={cn(ds.fullSpaceCenteredCol)}>
                {!fetchingProjectList && projectList && projectList.length > 0 ?
                  <Virtuoso
                    style={{ height: '100%', width: '100%' }}
                    data={projectList}
                    itemContent={(index, project) => (
                      <ProjectItem 
                        project={project}
                        isSelected={selectedProjectId !== undefined && selectedProjectId === project._id}
                        onPress={() => dispatch(RemoteSettingsActions.setSelectedProjectId(project._id))}
                        onPressDelete={() => handleDeleteProject(project._id, project.name)}
                        onPressEdit={() => handlePressEditProject(project)}
                      />
                    )}
                  />
                :
                  <NoData 
                    text={t("MainPage.RemoteSettingsTab.projectsNotFound")}
                    textColor={theme.colors.white}
                    loading={fetchingProjectList}
                  />
                }
              </Box>
              <Box className={cn(s.bottomButton)}>
                <CustomButton 
                  title={t("MainPage.RemoteSettingsTab.createNewProject")}
                  onPress={() => {
                    setProjectToEdit(null);
                    setCreateProjectModalVisible(true);
                  }}
                />
              </Box>
            </Box>
          </Box>

          <ResizeHandle height={"calc(100% - 48px)"} top={24} right={4}/>
        </Box>
      </ResizeableView>

      <div style={{width: rw}} className={cn(s.rightContainer)}>
        <Box className={cn(s.constructorCont)}>
          <Box className={cn(s.constructorHeader)}>
            <Box className={cn(s.constructorKeysSearchCont)}>
              <TextInput 
                value={searchKeysInnerValue}
                onChangeText={(text) => setSearchKeysInnerValue(text)}
                onClear={() => setSearchKeysInnerValue("")}
                labelColor={theme.colors.black}
                color={theme.colors.inputTextSecondary}
                placeholder={t("MainPage.RemoteSettingsTab.keysSearch")}
                validationOk={true}
                height={32}
                fontSize={16}
                paddingLeft={8}
                paddingRight={28}
                borderRadius={theme.metrics.x2}
                borderUnfocused={`solid 1px ${theme.colors.inputBorderColorUnactiveSecondary}`}
                bgColor={theme.colors.inputBgSecondary}
              />
            </Box>
            <Box className={cn(s.envSlidingPickerCont)}>
              <SlidingPickerV2 
                periods={['dev', 'stg', 'prod']}
                pickedPeriod={pickedEnv}
                setPickedPeriod={(i) => setPickedEnv(i)}
                height={34}
              />
            </Box>
            {(!!profile && selectedProjectId) &&
              <Box className={cn(s.constructorHeaderRightCont)}>
                <p className={cn(s.whiteTextBolder)}>
                  {`${t("Common.preferable")}:`}
                </p>

                <SwitchColored
                  checked={!!profile.projectsSetting?.[selectedProjectId]?.remoteSettingsEnabled}
                  onChange={(e) => handleToogleRemoteSettingsEnabled(e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                  title={t("MainPage.OtherTab.markFlagsAsPreferable")}
                />
              </Box>
            }
          </Box>
          <Box className={cn(s.constructorValuesCont)}>
            {(selectedProjectId && !fetchingRemoteSettings) ?
              <>
                <Box className={cn(ds.fullWidth)}>
                  <p className={cn(ds.whiteTextBolder)} style={{color: theme.colors.textMain}}>
                    {selectedProjectId ? `${t("MainPage.RemoteSettingsTab.keyValuePairsFor")} ${projectName ?? `projectId ${selectedProjectId}`} [${REMOTE_SETTINGS_ENV_ARRAY[pickedEnv]}]:` : t("MainPage.RemoteSettingsTab.noProjectSelected")}
                  </p>
                </Box>
                <ObjectConstructor 
                  value={remoteSettingsByProjectIdTable[selectedProjectId]?.[REMOTE_SETTINGS_ENV_ARRAY[pickedEnv]] ?? {}} 
                  fetchingSave={fetchingSetRemoteSettings}
                  searchQuery={searchKeysValue}
                  onChangeValue={(v) => handleSaveNewRemoteSettings(v)}
                />
              </>
            :
              <NoData 
                text={t("MainPage.RemoteSettingsTab.noProjectSelected")} 
                loading={fetchingRemoteSettings}
              />
            }
          </Box>
        </Box>
      </div>

      <CreateProjectModal 
        visible={createProjectModalVisible}
        onClose={() => setCreateProjectModalVisible(false)}
        onPressAddMember={() => {
          setMembersModalMode("add");
          setCreateProjectModalVisible(false);
          setMembersModalVisible(true);
        }}
        onPressRemoveMember={() => {
          setMembersModalMode("remove");
          setCreateProjectModalVisible(false);
          setMembersModalVisible(true);
        }}
        project={projectToEdit}
      />

      <MembersModal 
        visible={membersModalVisible}
        onClose={() => setMembersModalVisible(false)}
        project={projectToEdit}
        mode={membersModalMode}
      />
    </div>
  );
};

RemoteSettingsTab.defaultProps={}

export { RemoteSettingsTab };