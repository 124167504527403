import React, { useState } from 'react';
import { useStyles } from './RowItemStyles';
import { createElement } from 'react-syntax-highlighter';
import { LinesWithMarkup } from '~/helpers/syntaxHighlighterHelpers';
import { Pin } from './Pin';
import ArrowRightIcon from './assets/arrowRight';
import ArrowBottomIcon from './assets/arrowBottom';
import CopyIcon from '~/components/ApiEventItem/assets/copy';
import cn from 'classnames';

export type RowItemProps = {
  line: LinesWithMarkup;
  arrowsColor: string | undefined;
  infoColor: string;
  row: rendererNode;
  stylesheet: {
    [key: string]: React.CSSProperties;
  };
  keyString: string;
  pinned?: boolean;
  pinTitle?: string;
  arrayItemsText: string;
  onChangePin?: (newPinned: boolean) => void;
  onCopy: () => void;
  onFoldChange: (lineIndex: number, newOpened: boolean) => void;
};

const RowItem: React.FC<RowItemProps> = ({
  line,
  arrowsColor,
  infoColor,
  row,
  stylesheet,
  keyString,
  pinned,
  pinTitle,
  arrayItemsText,
  onChangePin,
  onCopy,
  onFoldChange
}) => {
  const s = useStyles();

  const [hovered, setHovered] = useState(false);

  const basicLeft = line.depth * 19.5;

  return (
    <div 
      className={cn(s.rowContainer)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {(onChangePin && line.showPin && (hovered || pinned)) &&
        <Pin 
          className={cn(s.pinButton)}
          left={basicLeft - 18} 
          pinned={pinned}
          defaultColor={arrowsColor}
          title={pinTitle}
          onChangePin={(p) => onChangePin(p)}
        />
      }
      
      {line.showArrow &&
        <div 
          onClick={() => onFoldChange(line.origIdx, !line.opened)}
          className={cn(s.arrowButton)}
          style={{left: basicLeft}}
        >
          {line.opened ?
            <ArrowBottomIcon color={arrowsColor} />
          :
            <ArrowRightIcon color={arrowsColor} />
          }
        </div>
      }

      {(!line.braceT && hovered) &&
        <div 
          onClick={onCopy}
          className={cn(s.copyButton)}
          style={{left: basicLeft}}
        >
          <CopyIcon color={arrowsColor}/>
        </div>
      }
      {
        createElement({
          node: row,
          stylesheet,
          // style: {},
          useInlineStyles: true,
          key: keyString
        })
      }
      {(line.isOB && !line.opened && line.braceT === "square" && line.itemsCount !== undefined) &&
        <div style={{display: "flex"}}> 
          <p style={{margin: 0, padding: 0, color: infoColor}}>
            {` ${line.itemsCount} ${arrayItemsText}`}
          </p>
        </div>
      }
    </div>
  );
};

export { RowItem };