type RunMode = "DEVELOPMENT" | "PRODUCTION";

const RUN_MODE: RunMode = "PRODUCTION";

type RunModeDependentValue = {[k in RunMode]: any};

const CONFIG_INNER = {
  RUN_MODE: RUN_MODE as RunMode,
  PRODUCT_NAME: "CodeBud",
  COMPANY_NAME: "App Klaar",
  MAIN_URL: "https://codebud.io",
  SOCKET_PATH: "/connect/socket.io",
  BASE_URL: 'https://codebud.io/api/v3',
  USE_REACTOTRON: false,
  API_DATA_CACHE_LIFETIME_HRS: 24,
  SOCKET_RECONNECTION_DELAY: 10e3,
  NAV_BAR_WIDTH: 64,
  RE_CAPTCHA: {
    SITE_KEY: "6Lf_QD0lAAAAACnT_HO7BL6xmaeLs-9cvIIv7x0E"
  },
  LINKS: {
    DOCUMENTATION: "https://docs.codebud.io/",
    FAQ: "https://docs.codebud.io/troubleshooting#faq",
    SETUP_CODEBUD_PROJECT: "https://docs.codebud.io/mainConcepts#projects",
    APPKLAAR_WEBSITE: "https://www.appklaar.com/",
    TELEGRAM: "https://t.me/codebudio",
    GITHUB: "https://github.com/Appklaar/CodeBud",
    CODEBUD_LANDING_WEBSITE: "https://codebud.io/",
    STORAGE_MONITOR: "https://docs.codebud.io/storageMonitor"
  },
  APP_INFO: {
    DISPLAY_VERSION: "4.1.3",
    DISPLAY_BUILD_NUMBER: "69"
  },
  JIRA_TOKEN: "ATATT3xFfGF00sIt3en8cNGYd4dPeAuN2-CRhtsm9ULn1IrT7tFbUFwwoitDtIBgBZNxKUbuyc54-9RT3CYYes_y7w7026GqKBtWQQYGAy8nM8OF4WIkHfBToiRSSnWDj5xJJwXHWeOgeF3985zzdALI3r02716Ri0jg1P4dQynDILew-HujpZs=D723CA25",
  SEGMENT_ANALYTICS: {
    "DEVELOPMENT": {
      WRITE_KEY: "dyCo9KMqijHOka7p7qxdEPf6KAGxM9m6"
    },
    "PRODUCTION": {
      WRITE_KEY: "RNXgzIze0fun69gxoC3fhBqyD7sfCGOL"
    }
  }
} as const;

export const CONFIG = {
  ...CONFIG_INNER,
  SEGMENT_ANALYTICS: CONFIG_INNER.SEGMENT_ANALYTICS[RUN_MODE]
};