import { RootState } from '~/store/rootReducer';
import { ALL_NAVIGATION_BAR_TABS } from '~/components/NavigationBar';

export const userSelector = (state: RootState) => state.user;
export const apiKeySelector = (state: RootState) => state.user.apiKey;
export const userTokenSelector = (state: RootState) => state.user.userToken;
export const navigationTabSelector = (state: RootState) => state.user.navigationTab;
export const testAccountsSelector = (state: RootState) => state.user.testAccounts;
export const currentTestAccountSelector = (state: RootState) => state.user.currentTestAccount;
export const connectedClientProjectIdSelector = (state: RootState) => state.user.connectedClientProjectId;
export const notChosenNavBarTabsSelector = (state: RootState) => ALL_NAVIGATION_BAR_TABS.filter((tab) => !state.user.chosenNavBarTabs.find((c) => c.id === tab.id));
export const projectsSettingSelector = (state: RootState) => state.user.profile?.data?.projectsSetting ?? {};