import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import { useStyles } from './SyntaxHighlighterStyles';
import { Box, IconButton } from '@material-ui/core';
import { CustomRendererView } from './CustomRendererView';
import { useDependentState } from '~/hooks';
import { handleFoldChange, jsonFromStringsWithBracesRecovery, LinesWithMarkup, markupJSON } from '~/helpers/syntaxHighlighterHelpers';
import { copyToClipboard } from '~/helpers/windowHelpers';
import { showToast } from '~/helpers/alertService';
import { useThemeContext } from '~/theme';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { appSettingsSelector } from '~/logic/appSettings/AppSettingsSelectors';
import { SYNTAX_HIGHLIGHTING_TABLE, SyntaxHighlightingType } from '~/constants/syntaxHighlightingTable';
import { Prism as SyntaxHighlighterPrism } from 'react-syntax-highlighter';
import CopyIcon from '~/components/ApiEventItem/assets/copy';
import cn from 'classnames';

export type SyntaxHighlighterProps = {
  code: string;
  // Внешний массив linesWithMarkup (например, из our(Redux/Zustand)Inspector)
  linesWithMarkup?: LinesWithMarkup[];
  highlighting?: any;
  enableFolding?: boolean;
  foldingController?: "outer" | "inner";
  scollToBottomOnUpdate?: boolean;
  arrowsColor?: string;
  infoColor?: string;
  customStyle?: React.CSSProperties;
  HighlighterComponent?: any;
  highlighterComponentType?: SyntaxHighlightingType;
  withPins?: boolean;
  pinnedPaths?: string[];
  borderRadius?: number;
  allowOverflowY?: boolean;
  fallbackDataKey?: string;
  virtualize?: boolean;
  showCopyAllButton?: boolean;
  onInspectorFoldChange?: (lineIndex: number, newOpened: boolean) => void;
  onPinPath?: (path: string) => void;
  onUnpinPath?: (path: string) => void;
};

const SyntaxHighlighterView: React.FC<SyntaxHighlighterProps> = ({
  code,
  linesWithMarkup,
  highlighting,
  enableFolding,
  foldingController,
  scollToBottomOnUpdate,
  arrowsColor,
  infoColor,
  customStyle,
  HighlighterComponent,
  highlighterComponentType,
  withPins,
  pinnedPaths,
  borderRadius,
  allowOverflowY,
  fallbackDataKey,
  virtualize,
  showCopyAllButton,
  onInspectorFoldChange,
  onPinPath,
  onUnpinPath
}) => {
  const { theme } = useThemeContext();
  const s = useStyles(theme)();
  const { t } = useTranslation();

  const {
    syntaxHighlighting
  } = useSelector(appSettingsSelector);

  const [innerLinesWithMarkup, setInnerLinesWithMarkup] = useDependentState<LinesWithMarkup[]>(() => {
    if (!enableFolding)
      return [];

    if (foldingController === "outer")
      return linesWithMarkup;

    try {
      const markup = markupJSON(code);
      return markup;
    } catch (e) {
      // Строка не является JSON(ом), отобразить хоть как-то
      const s = JSON.stringify({[fallbackDataKey!]: code}, null, 2);
      return markupJSON(s);
    }
  }, [enableFolding, foldingController, linesWithMarkup, code, fallbackDataKey]);

  const highlighterRef = useRef<null | HTMLDivElement>(null);

  const onFoldChange = useCallback((lineIndex: number, newOpened: boolean) => {
    if (foldingController === "outer") {
      onInspectorFoldChange?.(lineIndex, newOpened);
    } else {
      setInnerLinesWithMarkup(handleFoldChange(innerLinesWithMarkup, lineIndex, newOpened, true));
    }
  }, [foldingController, onInspectorFoldChange, innerLinesWithMarkup]);

  useEffect(() => {
    if (scollToBottomOnUpdate && highlighterRef?.current) {
      highlighterRef.current.scroll({ 
        top: highlighterRef.current.scrollHeight, 
        behavior: "auto"
      });
    }
  }, [code]);

  const { linesVisible, innerLinesWithMarkupFilteredVisible, codeVisible } = useMemo(() => {
    if (!enableFolding)
      return { linesVisible: [], innerLinesWithMarkupFilteredVisible: [], codeVisible: code};

    const linesVisible: string[] = [];
    const innerLinesWithMarkupFilteredVisible: LinesWithMarkup[] = [];

    innerLinesWithMarkup.forEach((line) => {
      if (line.visible) {
        linesVisible.push(line.line);
        innerLinesWithMarkupFilteredVisible.push(line);
      }
    });
    
    return { linesVisible, innerLinesWithMarkupFilteredVisible, codeVisible: jsonFromStringsWithBracesRecovery(linesVisible, innerLinesWithMarkupFilteredVisible) };
  }, [enableFolding, code, innerLinesWithMarkup]);

  const onCopyAllJson = useCallback(() => {
    copyToClipboard(code);
  }, [code]);

  const onCopySingleLine = useCallback((lineIndex: number) => {
    try {
      const line = linesVisible[lineIndex];
      const tokens = line.split(`": `);
      const value = tokens[1] ?? tokens[0];
      let valueClear = value.trim();
      if (valueClear[valueClear.length - 1] === ",")
        valueClear = valueClear.slice(0, valueClear.length - 1);

      copyToClipboard(valueClear);
    } catch (e) {
      showToast(t("Toast.unableToCopy"), "error");
    }
  }, [linesVisible, t]);

  return (
    <div className={cn(s.container, {[s.containerOverflowYHidden]: !allowOverflowY, [s.containerOverflowYScroll]: allowOverflowY})} ref={highlighterRef}>
      {showCopyAllButton &&
        <Box className={cn(s.sideButtonCont)}>
          <IconButton
            onClick={(e) => onCopyAllJson()} 
            size='small'
            className={cn(s.copyAll)}
            title={t("Other.copyAllAsJSON")}
          >
            <CopyIcon color={theme.colors.bermudaGray}/>
          </IconButton>
        </Box>
      }

      <HighlighterComponent
        language="javascript" 
        style={highlighting ?? SYNTAX_HIGHLIGHTING_TABLE[highlighterComponentType!][syntaxHighlighting]}
        wrapLines={enableFolding}
        // showLineNumbers={enableFolding}
        customStyle={customStyle ?? {width: '100%', minHeight: '100%', height: enableFolding ? 'auto' : 'max-content', margin: 0, borderRadius}}
        codeTagProps={{
          // lineHeight: "inherit",
          fontSize: "inherit",
        }}
        renderer={
          enableFolding ?  
            ({ rows, stylesheet }: rendererProps) => (
              <CustomRendererView 
                rows={rows}
                // linesWithMarkup={linesWithMarkup}
                linesWithMarkup={innerLinesWithMarkupFilteredVisible}
                arrowsColor={arrowsColor ?? theme.colors.bermudaGray}
                infoColor={infoColor ?? theme.colors.gray}
                stylesheet={stylesheet}
                virtualize={virtualize}
                withPins={withPins as boolean}
                pinnedPaths={pinnedPaths}
                pinTitle={t("Other.pinThisObject")}
                arrayItemsText={t("Other.arrayItemsText")}
                onCopy={onCopySingleLine}
                onFoldChange={onFoldChange}
                onPinPath={onPinPath}
                onUnpinPath={onUnpinPath}
              />
            )
          : undefined
        }
      >
        {codeVisible}
      </HighlighterComponent>
    </div>
  );
};

SyntaxHighlighterView.defaultProps={
  HighlighterComponent: SyntaxHighlighterPrism, // Меняю дефолтный на prism
  highlighterComponentType: "prism",
  enableFolding: false,
  scollToBottomOnUpdate: false,
  foldingController: "outer",
  withPins: false,
  borderRadius: 6,
  allowOverflowY: false,
  fallbackDataKey: "data",
  showCopyAllButton: true
}

export { SyntaxHighlighterView as SyntaxHighlighter };