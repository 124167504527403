import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '~/theme';

export const useStyles = (theme: Theme) => makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    border: `1px solid ${theme.colors.borderMain}`,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    paddingTop: theme.metrics.x3,
    paddingBottom: theme.metrics.x3,
    paddingLeft: theme.metrics.x5,
    paddingRight: theme.metrics.x5,
    cursor: 'pointer'
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  textWhite: {
    margin: 0,
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f600.fontFamily,
    textOverflow: 'ellipsis',
    overflow: "hidden",
    whiteSpace: 'pre'
  },
  grayText: {
    color: theme.colors.textTetriary,
    fontFamily: theme.fonts.f400.fontFamily,
    margin: 0
  },
  textWhiteBold: {
    margin: 0,
    color: theme.colors.textMain,
    fontFamily: theme.fonts.f500.fontFamily,
    fontSize: 14,
  },
  topLeft: {
    display: 'flex',
    width: '88%',
    maxWidth: '88%',
    // backgroundColor: 'red'
  },
  topRight: {
    display: 'flex',
    width: '12%',
    justifyContent: 'flex-end',
    marginLeft: 8
  },
  bottom: {
    width: '100%'
  }
});