import React, { useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { RowItem } from './RowItem';
import { LinesWithMarkup } from '~/helpers/syntaxHighlighterHelpers';
import { ROW_ITEM_HEIGHT } from './RowItemStyles';

export type CustomRendererViewProps = {
  rows: rendererNode[];
  linesWithMarkup: LinesWithMarkup[]
  arrowsColor: string | undefined;
  infoColor: string;
  stylesheet: {
    [key: string]: React.CSSProperties;
  };
  withPins: boolean;
  pinTitle?: string;
  pinnedPaths?: string[];
  virtualize?: boolean;
  arrayItemsText: string;
  onFoldChange: (lineIndex: number, newOpened: boolean) => void;
  onCopy: (lineIndex: number) => void;
  onPinPath?: (path: string) => void;
  onUnpinPath?: (path: string) => void;
};

const CustomRendererView: React.FC<CustomRendererViewProps> = ({
  rows,
  linesWithMarkup,
  arrowsColor,
  infoColor,
  stylesheet,
  withPins,
  pinTitle,
  pinnedPaths,
  virtualize,
  arrayItemsText,
  onFoldChange,
  onCopy,
  onPinPath,
  onUnpinPath
}) => {
  const pinnedPathsSet = useMemo(() => new Set(pinnedPaths ?? []), [pinnedPaths]);

  const onChangePin = (index: number, newPinned: boolean) => {
    if (newPinned)
      onPinPath?.(linesWithMarkup[index].path);
    else
      onUnpinPath?.(linesWithMarkup[index].path);
  }

  if (virtualize)
    return (
      // <div style={{display: 'flex', height: 2000}}>
        <Virtuoso
          style={{ height: "100%" }}
          overscan={700}
          data={rows}
          fixedItemHeight={ROW_ITEM_HEIGHT}
          defaultItemHeight={ROW_ITEM_HEIGHT}
          itemContent={(index, row) => {
            // if (!linesWithMarkup[index]?.visible)
            //   return null;

            return (
              <RowItem 
                keyString={`CRL_${index}`}
                line={linesWithMarkup[index]}
                arrowsColor={arrowsColor}
                infoColor={infoColor}
                row={row}
                stylesheet={stylesheet}
                pinTitle={pinTitle}
                arrayItemsText={arrayItemsText}
                pinned={pinnedPathsSet.has(linesWithMarkup[index].path)}
                onFoldChange={onFoldChange}
                onCopy={() => onCopy(index)}
                onChangePin={withPins ? (newPinned) => onChangePin(index, newPinned) : undefined}
              />
            );
          }}
        />
      // </div>
    );

  return (
    <div style={{ height: '100%' }}>
      {rows.map((row, index) => {
        if (!linesWithMarkup[index]?.visible)
          return null;

        const key = `CRL_${index}`;

        return (
          <RowItem 
            key={key}
            keyString={key}
            line={linesWithMarkup[index]}
            arrowsColor={arrowsColor}
            infoColor={infoColor}
            row={row}
            stylesheet={stylesheet}
            pinTitle={pinTitle}
            arrayItemsText={arrayItemsText}
            pinned={pinnedPathsSet.has(linesWithMarkup[index].path)}
            onFoldChange={onFoldChange}
            onCopy={() => onCopy(index)}
            onChangePin={withPins ? (newPinned) => onChangePin(index, newPinned) : undefined}
          />
        );
      })}
    </div>
  );
};

CustomRendererView.defaultProps={
  virtualize: false
}

export { CustomRendererView };